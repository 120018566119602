import React from "react";
import { SEO, PageLayout, OutboundLink } from "@bluefin/components";
import { Grid, Header, Card, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class OrderOnlinePage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={null}>
          <Grid
            stackable={true}
            className={"component-section-container olo-cards"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={12}>
              <Header as={"h1"} inverted={false}>
                Order Online
              </Header>
              <Grid
                stackable={true}
                verticalAlign={"middle"}
                columns={"2"}
                centered={true}
                textAlign={"center"}
              >
                <Grid.Column textAlign={"center"}>
                  <Card>
                    <Card.Content>
                      <Card.Header>Order Online</Card.Header>
                    </Card.Content>
                    <Card.Content extra={true}>
                      <Button
                        primary={true}
                        size={"small"}
                        to={
                          "https://order.toasttab.com/online/quesosnoblesville"
                        }
                        as={OutboundLink}
                      >
                        Noblesville
                      </Button>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column textAlign={"center"}>
                  <Card>
                    <Card.Content>
                      <Card.Header>Order Online</Card.Header>
                    </Card.Content>
                    <Card.Content extra={true}>
                      <Button
                        primary={true}
                        size={"small"}
                        to={
                          "https://order.toasttab.com/online/quesosburlington"
                        }
                        as={OutboundLink}
                      >
                        Burlington
                      </Button>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column textAlign={"center"}>
                  <Card>
                    <Card.Content>
                      <Card.Header>Order Online</Card.Header>
                    </Card.Content>
                    <Card.Content extra={true}>
                      <Button
                        primary={true}
                        size={"small"}
                        to={
                          "https://order.toasttab.com/online/quesos-mexican-bar-grill-plainfield"
                        }
                        as={OutboundLink}
                      >
                        Plainfield
                      </Button>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Order Online" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
